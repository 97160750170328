import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'
import axios from 'axios';

import { ToastPlugin, useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import bootstrap from 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import moment from 'moment'
require('moment/locale/tr')
import CryptoJS from 'crypto-js'

import "@/assets/packet/fontawesome-free-5.15.3-web/css/all.min.css"
import "@/assets/css/eklentiler.css"
import "@/assets/css/cards.css"
import "@/assets/css/Style.css"
import "@/assets/css/sweetalert2.min.css"

const app = createApp(App)
app.use(VueSweetalert2);
app.use(ToastPlugin)
app.use(bootstrap)

app.config.globalProperties.$crypto = {
    encrypted(data) {
        const ciphertext = CryptoJS.AES.encrypt(data, process.env.VUE_APP_passphrase).toString();
        return ciphertext
    },
    decrypted(data) {
        const decryptedText = CryptoJS.AES.decrypt(data, process.env.VUE_APP_passphrase).toString(CryptoJS.enc.Utf8);
        return decryptedText
    },
    sayMyAuth(data, main) {
        const decryptedText = CryptoJS.AES.decrypt(data, process.env.VUE_APP_passphrase).toString(CryptoJS.enc.Utf8)
        var auth = JSON.parse(decryptedText).userAuthority.filter(x => x.main == main)[0]
        if (auth != undefined) return auth.sub
        else return []
    },
    sha256(data) {
        const decryptedText = CryptoJS.SHA256(data).toString();
        return decryptedText
    }
}

app.config.globalProperties.$moment = {
    moment(value) {
      return moment(value)
    },
    now() {
        return moment();
    },
    long() {
        return moment().format("YYYY-MM-DD HH:mm:ss")
    },
    date() {
        return moment().format("YYYY-MM-DD")
    },
    lastDay(count) {
        return moment().startOf('day').subtract(count, 'days').format('YYYY-MM-DD')
    },
    ago(value) {
        return moment(value).startOf().fromNow();
    },
    name() {
        return moment().format("YYYYMMDDHHmmssms")
    }
}

app.config.globalProperties.$alertBox = {
    success(msg) { alertBox({ type: 'success', msg: msg }) },
    error(msg) { alertBox({ type: 'error', msg: msg }) },
    info(msg) { alertBox({ type: 'info', msg: msg }) },
    warning(msg) { alertBox({ type: 'warning', msg: msg }) },
    cancel() { alertBox({ type: 'warning', msg: 'İşlem iptal edildi' }) },
    ops() { alertBox({ type: 'warning', msg: 'Sanırım bir şeyler ters gitti. Daha sonra tekrar deneyin' }) }
}

app.config.globalProperties.$axios = {
    async getAuth({ valueData }) {
        try {
            var req = await axios.get(store.state.axiosURL + '/users/getAuth', { params: { token: localStorage.token, value: valueData } })
            if (req.status == 200) {
                if (req.data.length > 0) return req.data
                else {
                    /* router.replace('/') */
                    return []
                }
            }
            else alertBox({ type: 'success', msg: req.data.message })
        } catch (error) {
            if (error.response) alertBox({ type: 'warning', msg: error.response.data.message })
            else console.error(error.message)
            return []
        }
    },
    async get({ uri, valueData, searchData }) {
        try {
            var req = await axios.get(store.state.axiosURL + uri, { params: { token: localStorage.token, value: valueData, search: searchData } })
            if (req.status == 200) return req.data
            else alertBox({ type: 'success', msg: req.data.message })
        } catch (error) {
            if (error.response) alertBox({ type: 'warning', msg: error.response.data.message })
            else console.error(error.message)
            router.replace({ query: null })
            return []
        }
    },
    async delete({ uri }) {
        try {
            var req = await axios.delete(store.state.axiosURL + uri, { params: { token: localStorage.token } })
            if (req.status == 200) return true
            this.$alertBox.success(req.data.message)
        } catch (error) {
            if (error.response) alertBox({ type: 'warning', msg: error.response.data.message })
            else console.error(error.message)
            return false
        }
    },
    async post({ uri, valueData }) {
        await axios.post(store.state.axiosURL + uri, { token: localStorage.token, value: valueData })
            .then(response => {
                alertBox({ type: 'success', msg: response.data })
            })
            .catch(error => { 
                // console.log(error)
                if (error.response) alertBox({ type: 'warning', msg: error.response.data.message })
                else console.error(error.message)
            });
    }
}

export function alertBox({ type, msg }) {
    useToast().open({
        position: store.state.toastrAlert.alertPosition,
        message: msg,
        type: type,
        duration: store.state.toastrAlert.alertduration,
        pauseOnHover: true,
    });
}

app.config.globalProperties.$filters = {
    formatL(value) {
      if (value != null || value != undefined) {
        value = Number(value)
        if (value < 0) {
          return (0 + ' ml')
        }
        else if (value <= 999) {
          return (value + ' ml')
        }
        else if (value > 999 && value <= 9999) {
          return (value / 1000).toFixed(2) + ' L'
        }
        else if (value > 9999 && value <= 99999) {
          return (value / 1000).toFixed(2) + ' L'
        }
        else if (value > 99999 && value <= 999999) {
          return (value / 1000).toFixed(1) + ' L'
        }
        else {
          return (value / 1000000).toFixed(2) + ' kL'
        }
      }
    },
    formatKG(value) {
      if (value != null || value != undefined) {
        value = Number(value)
        if (value <= 999) {
          return value + ' gr'
        }
        else if (value > 999 && value <= 999999) {
          return (value / 1000).toFixed(1) + ' KG'
        }
        else if (value > 999999) {
          return (value / 1000000).toFixed(2) + ' Ton'
        }
      }
    },
    sjonFormatter(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
    formatB(value) {
      if (value != null || value != undefined) {
        value = Number(value)
        if (value < 0) {
          return (0 + ' mBar')
        }
        else if (value <= 999) {
          return (value.toFixed() + ' mBar')
        }
        else if (value > 999 && value <= 9999) {
          return (value / 1000).toFixed(2) + ' Bar'
        }
        else if (value > 9999 && value <= 99999) {
          return (value / 1000).toFixed(2) + ' Bar'
        }
        else {
          return (value / 1000).toFixed() + ' Bar'
        }
      }
    },
    formatT(value) {
      if (value != null || value != undefined) {
        value = Number(value)
        const min = value / 60
        const sec = value - (Math.floor(min) * 60)
        if (value < 60) {
          return (`${value} sn`)
        }
        else {
          return (`${(min).toFixed()}:${sec} dk`)
        }
      }
    },
  }

app.config.globalProperties.$fileCheck = {
    itemImage(folder, value) {
        try {
            if (value == null || value == undefined) return require(`@/assets/media/img/${'null.png'}`)
            else return require(`@/assets/media/img/${folder}/${value}`)
        } catch (error) {
            return require(`@/assets/media/img/${'delete.png'}`)
        }
    }
}

app.use(store).use(router).mount('#app')
