import { createStore } from 'vuex'
const ioClient = require('socket.io-client');
const socketDatabase = ioClient.connect(window.location.protocol+"//" + '94.154.34.33' + ':3005/', { reconnect: true });
// import createPersistedState from 'vuex-persistedstate';

socketDatabase.on('exitStationData', (data) => {
  console.log(data)
});

const store = createStore({
  state: {
    toastrAlert: {
      alertPosition: 'top-right',
      alertduration: 5000,
    },
    calibrations: {
      auth: {
        authority: true,
        userMail: '',
        authorization: [],
        authList: []
      },
      period: {
        'default': {
          maxPeriodCount: 8,
          minDailyFeeding: 0,
          maxDailyFeeding: 15000,
          permPeriodMinFeeding: 0,
          permPeriodMaxFeeding: 5000
        },
        'selected': {
          periodCount: 3,
          dailyFeeding: 7500,
          periodMaxFeeding: 2500
        }
      },
    },
    orderBox: [],
    errorCodeDesc: {
      '0': 'PLC stop',
      '1': 'Hazır',
      '20': 'Normal Yıkama',
      '21': 'Normal Yıkama',
      '22': 'Baz Yıkama',
      '24': 'Asit Yıkama',
      '26': 'Tahliye yapılıyor',
      '27': 'Tahliye yıkama yapılıyor',
      '28': 'Yeniden başlamış',
      '30': 'Besleme yapılıyor',
      '100': 'Mikser sıvı seviye hatası',
      '101': 'Alınacak su miktarı uygun aralıkta değil',
      '102': 'Alınacak süt miktarı uygun aralıkta değil',
      '103': 'Su/mama oranı uygun aralıkta değil',
      '104': 'Kazan sıcaklığı uygun aralığın üzerinde',
      '105': 'Mikser minimum sıcaklığı uygun aralıkta değil',
      '106': 'Mikser maksimum sıcaklığı uygun aralıkta değil',
      '107': 'Sıvı basınç şarteli hatası (sistemde su yok)',
      '108': 'Helezon motoru hatası (sistemde mama yok veya tartı problemi)',
      '109': 'Tartım haznesi mama yapışma hatası',
      '110': 'Tahliye hattı 3 yollu vana yön bilgisi gelmeme hatası',
      '111': 'Kazan sıcaklığı mikser maksimum sıcaklığından düşük',
      '112': 'Emzik yıkama sıvı seviye önlemi uyarısı',
      '113': 'Makine manuel modda',
      '114': 'Emzik hattı normal yıkama sonlandırma uyarısı',
      '115': 'Emzik hattı kimyasal yıkama sonlandırma uyarısı',
      '116': 'Kazan ısısı kritik seviyede',
      '117': 'Tahliye hattı tıkanma veya olağandışı tahliye durumu',
      '118': 'Elektrik kesintisi, kazan sıcaklığı düşürme hatası',
      '119': 'Ani güç kesintisi veya dengesiz besleme',
      '120': 'Hazırlanan mama miktarının üzerinde besleme durumu',
      '121': 'Mama haznesi mama miktarı aşırı yükleme',
      '122': 'Yıkama yapılırken hat tıkanma durumu',
      '123': 'Helezon motoru dönme uyarısı, kayış sağlığını kontrol edin',
      '124': 'Harici besleme yapılırken hat tıkanma durumu',
      '125': 'Sıvı basınç şarteli hatası (Su basıncı kritik seviyede yüksek)',
      '130': 'Makine geçici süreliğine dinlenme moduna alındı',
    },
    errors: [
      
    ],
    calfGender: {
      'X': 'Belirtilmemiş',
      'F': 'Dişi',
      'M': 'Erkek',
    },
    calfGenderList: [
      {
        type: 'X',
        desc: 'Belirtilmedi'
      },
      {
        type: 'F',
        desc: 'Dişi'
      },
      {
        type: 'M',
        desc: 'Erkek'
      }
    ],
    axiosURL: "http://" + '94.154.34.33' + ':3005'
    // axiosURL: window.location.protocol + "//" + window['location'].hostname + ':3005'
  },
  getters: {
  },
  mutations: {
    collapse() {
      try {
        if (document.querySelector("body").classList != "collapse") {
          document.querySelector("body").classList.add("collapse")
          document.getElementById("logo-big").classList.add("d-none")
          document.getElementById("logo-small").classList.remove("d-none")
        } else {
          document.querySelector("body").classList.remove("collapse")
          document.getElementById("logo-big").classList.remove("d-none")
          document.getElementById("logo-small").classList.add("d-none")
        }
      } catch (error) {
        console.error(error)
      }
    },
    fullScreen() {
      if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) { /* Chrome, Safari & Opera */
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (document.msRequestFullscreen) { /* IE/Edge */
          document.documentElement.msRequestFullscreen();
        }
        document.querySelector("#fsBtn").firstChild.classList.remove("fa-expand-arrows-alt", "zoom");
        document.querySelector("#fsBtn").firstChild.classList.add("fa-compress-arrows-alt", "fs-5", "re-zoom");
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) { /* Chrome, Safari and Opera */
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen();
        }
        document.querySelector("#fsBtn").firstChild.classList.remove("fa-compress-arrows-alt", "fs-5", "re-zoom");
        document.querySelector("#fsBtn").firstChild.classList.add("fa-expand-arrows-alt", "zoom");
      }
    },
    navOptionsBar() {
      document.getElementById('navOptionsBar').classList.toggle("open")
    },
    async logout() {
      this.state.calibrations.auth.authority = false
      this.state.calibrations.auth.userMail = ''
      this.state.calibrations.auth.authorization = []
      localStorage.removeItem('token')
      localStorage.removeItem('authorization')
    },
  },
  actions: {
  },
  modules: {
  },
  // plugins: [createPersistedState()],
})

export default store
