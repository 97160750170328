<template>
    <div class="box-white border-top-outline-success process" style="max-width: 800px;">
        KKDDK
    </div>
</template>

<script>
export default {
    name: 'kvkkPage',
    components: {},
    data() {
        return {
        }
    },
    methods: {
    },
    async mounted() {
    },
    computed: {},
    watch: {
    }
}
</script>
