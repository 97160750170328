<template>
  <div class="spinners" v-if="spinners">
    <atom-spinner :animation-duration="1000" :size="100" color="#ff1d5e" />
  </div>
  <header v-if="this.auth.authority">
    <ul>
      <li @click="collapse"><i class="fas fa-bars btn-aside zoom"></i></li>
    </ul>
    <div v-if="this.selectedMachine != undefined">
      <span>{{ this.$store.state.machineData.filter(x => x.machine_id == this.selectedMachine)[0]?.change_date.slice(0, 19) }}</span>
    </div>
    <ul>
      <li id="loginBtn" v-if="this.auth.authority" @click="exitAccount()">
        <i class="fas fa-sign-in-alt"></i>
      </li>
      <li id="fsBtn" @click="fullScreen">
        <i class="fas fa-expand-arrows-alt zoom"></i>
      </li>
    </ul>
  </header>

  <nav v-if="this.auth.authority">
    <div class="logo-details big">
      <div id="logo-small" class="logo d-none">
        <img src="@/assets/media/img/logo.png" alt="" />
      </div>
      <div id="logo-big" class="logo">
        <img src="@/assets/media/img/logo2.png" alt="" />
      </div>
      <span class="logo-name line-limit-1">..\BilirArsiv</span>
    </div>
    <ul class="nav-list">
      <li class="nav-item" v-if="this.auth.authList.includes('user')">
        <a class="nav-link">
          <i class="fas fa-users"></i>
          <router-link class="nav-title fw-bold" to="/panel/users">Kullanıcılar</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.role == 'admin' && false">
        <a class="nav-link">
          <i class="fas fa-database"></i>
          <router-link class="nav-title fw-bold" to="/panel/archives">Veriler</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authList.includes('token')">
        <a class="nav-link">
          <i class="fas fa-key"></i>
          <router-link class="nav-title fw-bold" to="/panel/tokens">Lisanslar</router-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/panel/machines">Makine listesi</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('map')">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/panel/map">Harita</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('version')">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/panel/path/versions">Sürüm kontrol</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('memory')">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/panel/path/memory">Hafıza kontrol</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('memory')">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/panel/path/systems">Sistemler</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('error')">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/panel/path/errors">Uyarılar</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authList.includes('machine') && false">
        <a class="nav-link" @click="dropdown($event)">
          <i class="fas fa-bars"></i>
          <i class="fas fa-chevron-down arrow"></i>
          <span class="nav-title fw-bold">Makine takip</span>
        </a>
        <ul class="sub-list">
          <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0]?.sub.includes('service')">
            <a class="nav-link">
              <i class="fas fa-bars"></i>
              <router-link class="nav-title fw-bold" to="/panel/path/services">Servisler</router-link>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item" v-if="false">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/panel/path/technicalDetails">Teknik kontrol</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'order')[0]?.sub.includes('*') || this.auth.authorization.filter(x => x.main == 'order')[0]?.sub.includes('service')">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/panel/orders">Emirler</router-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/myaccount">Hesabım</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="false">
        <a class="nav-link" @click="dropdown($event)">
          <i class="fas fa-home"></i>
          <i class="fas fa-chevron-down arrow"></i>
          <span class="nav-title fw-bold">Sub Item</span>
        </a>
        <ul class="sub-list">
          <li class="nav-item">
            <a class="nav-link">
              <i class="fas fa-chart-line"></i>
              <span class="nav-title fw-bold" to="/panel/baskets">Item 1</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <i class="fas fa-chart-line"></i>
              <span class="nav-title fw-bold" to="/panel/baskets">Item 2</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <div class="nav-profil">
      <div class="profil-img">
        <img src="@/assets/media/img/logo.png" alt="" @click="userSettings" />
      </div>
      <p class="profil-name line-limit">{{ this.auth.userMail }}</p>
    </div>
  </nav>

  <main class="container-xxxl" v-if="this.auth.authority">
    <div class="content">
      <div class="main-header">
        <p class="page-name">{{ this.breadcrumb[this.breadcrumb.length - 1] }}</p>
        <ul class="breadcrumb">
          <li><a href="/">AnaMenü</a></li>
          <li v-for="(item, index) in this.breadcrumb" :key="index">{{ item }}</li>
        </ul>
      </div>
      <router-view v-if="this.$route.path.split('/')[1] == 'panel'" @breadcrumb="breadcrumb = $event" />
      <router-view v-else @breadcrumb="breadcrumb = $event" :selectedMachine="this.selectedMachine" />
    </div>
  </main>

  <footer v-if="this.auth.authority">
    <div class="content">
      <p>© {{ this.$moment.date().slice(0, 4) }}</p>
      <p>v1.12.0</p>
    </div>
  </footer>
  <div class="popup" v-if="this.$route.query.login != null">
    <div class="background-area" @click="this.$router.replace({ query: null })"></div>
  </div>
  <div class="specialLoginArea" v-if="!this.auth.authority">
    <loginPage />
  </div>
</template>

<style scoped>
.error-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 110;
  width: 100vw;
  padding: 1em;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error-alert span {
  max-width: 75%;
}

.fastAccessList {
  padding: 0;
}

.fastAccessList .fastAccessList-item:first-child {
  margin-top: 3em;
}

.fastAccessList .fastAccessList-item {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 1em;
  border-radius: 0.5em;
  padding: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.fastAccessList .fastAccessList-item i {
  padding: 0 0.5em;
}

.perde {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.3);
}

#technicalNote {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}

#technicalNote p {
  margin-bottom: 200px;
  font-size: 1.5em;
  max-width: 500px;
  text-align: center;
  font-weight: bold;
}

input {
  width: 100%;
  height: 100px;
  padding: 20px;
  font-size: 20px;
  border: none;
  box-sizing: border-box;
}

.simple-keyboard {
  max-width: 850px;
}

.specialLoginArea {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script>
import axios from 'axios';
import { mapMutations } from "vuex";
import { AtomSpinner } from "epic-spinners";
import loginPage from '@/views/login.vue'

export default {
  components: {
    AtomSpinner,
    loginPage
  },
  data() {
    return {
      spinners: true,
      navOptionsBarShow: false,
      breadcrumb: ['Anamenü'],
      selectedMachine: undefined,
      dataFastAccess: {
        reboot: true,
        errorReset: true,
      },
    };
  },
  methods: {
    ...mapMutations([
      "collapse",
      "fullScreen",
      "navOptionsBar",
      "logout"
    ]),
    dropdown(event) {
      event.target.parentElement.parentElement.classList.toggle("active")
      event.target.parentElement.parentElement.classList.toggle("open")
    },
    navOptionsBar0() {
      this.navOptionsBar();
      this.navOptionsBarShow = !this.navOptionsBarShow;
    },
    async checkByToken() {
      if (localStorage.token != undefined) {
        try {
          var req = await axios.get(this.$store.state.axiosURL + '/users/checkByToken', { params: { token: localStorage.token } })
          if (req.status == 200) {
            this.auth.authority = true
            this.auth.userID = req.data.userID
            this.auth.role = req.data.userRole
            this.auth.userMail = req.data.userMail
            this.auth.authorization = req.data.userAuthority
            this.auth.authList = req.data.authList
          }
          else this.$alertBox.success(req.data)
        } catch (error) {
          if (error.response) this.$alertBox.warning(error.response.data)
          else console.error(error.message)
          this.logout()
        }
      }
      else this.auth.authority = false
      this.spinners = false;
    },
    async serviceReboot({ service }) {
      this.$swal({
        title: "Servisi yeniden başlatmak istediğinize emin misiniz?",
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: "İşlemi onayla",
        denyButtonText: `Vazgeç`,
        timer: this.$store.state.toastrAlert.alertduration,
        timerProgressBar: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var item = {
              userID: this.userID,
              machine_id: this.selectedMachine,
              orderType: 0,
              orderService: this.orderBox.services?.filter(x => x.serviceTag == service)[0].id
          }
          await this.$axios.post({ uri: '/orders/fastAccess/', valueData: item })
        }
        else this.$alertBox.cancel()
      });
    },
    async fastAccess(type) {
      this.$swal({
        title: type + " işlemi yapmak istediğinize emin misiniz?",
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: "İşlemi onayla",
        denyButtonText: `Vazgeç`,
        timer: this.$store.state.toastrAlert.alertduration,
        timerProgressBar: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var item = {
              userID: this.userID,
              machine_id: this.selectedMachine,
              orderType: 90,
              orderService: 1,
              orderContent: 'W-M-0-0'
          }
          if (type == "reboot") item.orderContent = 'W-M-43-1-0'
          else if (type == "manual") item.orderContent = 'W-M-0-1'
          else if (type == "automatic") item.orderContent = 'W-M-0-0'
          else if (type == "errorReset") item.orderContent = 'W-D-98-1'
          else if (type == "washingNormal") item.orderContent = 'W-M-345-1'
          await this.$axios.post({ uri: '/orders/fastAccess/', valueData: item })
        } else this.$alertBox.cancel()
      });
    },
    exitAccount() {
      this.$swal({
        title: 'Çıkış yapmak istediğinize emin misiniz',
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: `Vazgeç`,
        timer: this.$store.state.toastrAlert.alertduration,
        timerProgressBar: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.logout()
          }
        })
    },
    async loop() {
      const machineData = this.userID ? await this.$axios.get({ uri: '/machines/getMyMachines/' + this.userID }) : undefined
      this.$store.state.machineData = machineData
      if (this.$store.state.machineData?.length > 0) {
        this.selectedMachine = this.$store.state.machineData[0].machine_id
        this.$store.state.serviceData = this.$store.state.machineData.filter(x => x.machine_id == this.selectedMachine)[0].other_data.services?.services
      }
    }
  },
  async mounted() {
    await this.checkByToken()
    if (window.innerWidth < 992) this.collapse()
    if (this.userID != undefined) this.$store.state.machineData = await this.$axios.get({ uri: '/machines/getMyMachines/' + this.userID })
    clearInterval()
    this.loop()
    setInterval(async () => {
      // this.loop()
    }, 5000)
    this.$store.state.orderBox = await this.$axios.get({ uri: '/orders/getAllOrders' })
  },
  computed: {
    auth() {
      return this.$store.state.calibrations["auth"]
    },
    myMachines() {
      if (this.selectedMachine != undefined) {
        return this.$store.state.machineData
      }
      else return undefined
    },
    services() {
      if (this.selectedMachine != undefined) {
        return this.$store.state.machineData.filter(x => x.machine_id == this.selectedMachine)[0].other_data.services?.services
      }
      else return undefined
    },
    controlPLC() {
      if (this.selectedMachine != undefined) {
        return this.$store.state.machineData.filter(x => x.machine_id == this.selectedMachine)[0].other_data.plc
      }
      else return undefined
    },
    stationCount() {
      return this.$store.state.machineData.filter(x => x.machine_id == this.selectedMachine)[0].machinestationCount
    },
    orderBox() {
      return this.$store.state.orderBox
    },
    userID() {
      return this.$store.state.calibrations.auth.userID
    }
  }
};
</script>
